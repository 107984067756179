<template>
  <transition name="fade" appear>
    <div id="practiceExercise">
      <section class="headNavInfo">
        <div class="headNavInfo_title">当前位置：<span class="hoverText" @click="goBack">首页</span>>>公告</div>
      </section>
      <section class="box">
        <div class="left">
          <div
            v-for="(item, index) in monthList"
            :key="item.id"
            @click="changeTab(index, item)"
            :class="[checkIndex === index ? 'theme-curr' : '', 'theme-h', 'f16']"
          >
            <el-row :gutter="10" class="sec-d">
              <el-col :span="24">
                <span class="sec">{{ item.month }}</span>
              </el-col>
            </el-row>
          </div>
        </div>

        <div class="content">
          <div class="content_title">
            <div class="title_text">通知公告</div>
            <div class="title_line"></div>
          </div>

          <template v-if="noticeList.length > 0">
            <div class="content_item" v-for="(item) in noticeList" :key="item.id" @click="jumpDetail(item)">
              <div class="item_time">{{ item.createDt | dataFormat("y-m-d") }}</div>
              <div class="item_title">{{ item.title }}</div>
            </div>
          </template>
           
          <NoneImg v-else />

          <div class="pageInfo">
            <el-pagination
              background
              :page-size="queryInfo.pageSize"
              layout=" total,prev, pager, next, jumper"
              :total="count"
            >
            </el-pagination>
          </div>
        </div>
      </section>
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  data() {
    return {
      // 分页参数
      queryInfo: {
        currPage: 1,
        pageSize: 10,
      },
      monthInfo: {
        currPage: 1,
        pageSize: 100,
      },
      count: 0,
      monthList: [],
      monthCount: [],
      noticeList: [],
      checkIndex: null,
    };
  },
  methods: {
    // 返回上一页
    goBack() {
      this.$router.push({
        path: "/home",
      });
    },

    //详情
    jumpDetail(item) {
      this.$router.push({
        path: "/notice/detail",
        query: {
          noticeCode: item.noticeCode,
        },
      });
    },

    changeTab(index, item) {
      this.checkIndex = index;
      this.queryNoticeList(item.month);
    },

    // 修改当前页
    handleCurrentChange(newCurr) {
      this.queryInfo.currPage = newCurr;
    },

    queryMonthList() {
      var param = {
        ...this.monthInfo,
      };
      this.$api.notice.queryMonthList(param).then((res) => {
        if (res.data.code === 200) {
          this.monthList = res.data.data.data;
          this.monthCount = res.data.data.count;
          if (this.monthList.length > 0) {
            this.changeTab(0, this.monthList[0]);
          }
        }
      });
    },
    queryNoticeList(month) {
      var param = {
        ...this.queryInfo,
        month: month,
      };
      this.$api.notice.queryNoticeList(param).then((res) => {
        if (res.data.code === 200) {
          this.noticeList = res.data.data.data;
          this.count = res.data.data.count;
        }
      });
    },
  },
  created() {
    this.$store.commit(CHANGE_NAVINDEX, "1");
    this.queryMonthList();
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;
  width: 100%;
  height: 500px;
  background: white;
  .left {
    background: #f9fbfc;
    width: 250px;
    height: 100%;
    // padding: 12px 0;
    overflow-x: hidden;
    .title {
      font-weight: 700;
      font-size: 24px;
      padding: 0 20px;
    }
    .sec-d {
      cursor: pointer;
      height: 50px;
      line-height: 50px;
      padding: 0 20px;
      .sec {
        font-size: 16px;
        font-weight: 700;
      }
      .des {
        // color: #7f7f7f;
      }
    }

    .gray-bg {
      background: #ccc;
    }
  }

  .content {
    position: relative;
    width: 100%;
    padding: 0 17px 39px;
    .content_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title_text {
        position: relative;
        font-size: 18px;
        margin-right: 30px;
      }
      .title_text::after {
        content: "";
        position: absolute;
        width: 7px;
        height: 7px;
        top: 35%;
        left: 125%;
        background: #dd3938;
        border-radius: 50%;
      }
      .title_line {
        height: 1px;
        flex: 1;
        border: 0.5px dashed #ccccccff;
      }
    }
    .content_item {
      display: flex;
      padding: 8px 0;
      align-items: center;
      border-bottom: 1px dashed #bbbbbb;
      font-size: 16px;
      .item_time {
        width: 90px;
      }
      .item_title {
        width: 733px;
        padding-left: 22px;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        overflow: hidden;
      }
    }
  }
  .content_item:hover {
    cursor: pointer;
    .item_title,
    .item_time {
      color: #dd3938;
    }
  }
  .content_item:active {
    transform: translate(1px, 1px);
  }
  .pageInfo {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
